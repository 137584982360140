/**
 * @file Front Page.
 */

/**
 * Front Page functions.
 *
 * Runs js specific to the Front Page template.
 */
const vFrontPage = () => {
	import('../../components/hp-programs/hp-programs').then(({ default: vHpPrograms }) => { vHpPrograms(); });
};

if (document.readyState !== 'loading') {
	vFrontPage();
} else {
	document.addEventListener('DOMContentLoaded', vFrontPage);
}
